import React from 'react'

import ServicesNode from 'components/services/services-node'
import styles from './services-list.module.css'

const ServicesList = ({ nodes = [] }) => {
  const services = nodes.map((node, index) => {
    const { acf, path, status, title, featured_media } = node

    if (status !== 'publish' || !featured_media) return null

    const image = {
      fluid: featured_media.localFile.childImageSharp.fluid,
      alt_text: featured_media.alt_text
    }
    return <ServicesNode key={node.id} position={index} title={title} to={path} image={image} { ...acf } />
  })
  
  return (
    <section className={styles.container}>
      { services }
    </section>
  )
}

export default ServicesList