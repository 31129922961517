import React from 'react'
import { graphql } from 'gatsby'

import SEO from 'components/seo'
import ServicesList from 'components/services/services-list'
import Hero from 'components/layouts/hero'
import Footer from 'components/footer/footer'

const ServicesTemplate = ({ data }) => {
  const currentPage = data.wordpressPage
  const children = data.allWordpressPage.nodes
  const { blurb, custom_title } = currentPage.acf
  const featuredMedia = currentPage.featured_media
  let featuredImage = {}

  if (featuredMedia && featuredMedia.localFile) {
    featuredImage = {
      fluid: featuredMedia.localFile.childImageSharp.fluid,
      alt_text: featuredMedia.alt_text
    }
  }

  // Transform children and sort them by menu order
  const sortedChildren = children.sort((a, b) => (a.menu_order > b.menu_order) ? 1 : -1)
  
  return (
    <>
      <SEO title={currentPage.title} yoast={currentPage.yoast_meta && currentPage.yoast_meta} />
      <Hero content={blurb} featuredImage={featuredImage}>{ custom_title || currentPage.title }</Hero>
      <ServicesList nodes={sortedChildren} />
      <Footer />
    </>
  )
}

export default ServicesTemplate

export const pageQuery = graphql`
  query($id: String!, $wordpress_id: Int!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_twitter_title
        yoast_wpseo_website_name
        yoast_wpseo_person_name
        yoast_wpseo_metadesc
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_image
        yoast_wpseo_facebook_description
        yoast_wpseo_company_or_person
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_social_defaults {
          facebook_site
          instagram_url
          linkedin_url
          myspace_url
          og_default_image
          og_default_image_id
          og_frontpage_desc
          og_frontpage_image
          og_frontpage_image_id
          og_frontpage_title
          opengraph
          pinterest_url
          pinterestverify
          twitter
          twitter_card_type
          twitter_site
          wikipedia_url
          youtube_url
        }
      }
      acf {
        blurb
        custom_title
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 650, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }

    site {
      id
      siteMetadata {
        title
      }
    }

    allWordpressPage(filter: { wordpress_parent: { eq: $wordpress_id }}) {
      nodes {
        id
        slug
        status
        template
        path
        title
        menu_order
        acf {
          blurb
          icon
        }
        featured_media {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 650, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        wordpress_id
        wordpress_parent
      }
    }
  }
`
