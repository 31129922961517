import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AnimatedImage from 'components/image/animated-image'
import AnimatedButton from 'components/buttons/animated-button'
import styles from './services-node.module.css'

const AccentBlock = () => <span className={styles.accent}></span>

const ServicesNode = ({ blurb, icon, image, position, title, to }) => {
  let count = position + 1
  count = count.toString().padStart(2, '0')

  if (position % 2 === 1) {
    return (
      <section className={styles.node}>
        <div className={styles.nodeBorderReversed}></div>
        
        <div className={styles.imageWrapper}>
          <AnimatedImage image={image} className={styles.image} />
        </div>

        <div className={styles.contentWrapper}>
          <span className={styles.indicator}>{ count }</span>
  
          <div className={styles.titleContainer}>
            <FontAwesomeIcon icon={['fal', icon]} className={styles.icon} />
            <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }}></h2>
          </div>
  
          <AccentBlock />
  
          <div className={styles.content} dangerouslySetInnerHTML={{ __html: blurb }}></div>
          <AnimatedButton to={to} message={'Learn More'} />
        </div>
      </section>
    )
  } else {
    return (
      <section className={styles.node}>
        <div className={styles.nodeBorder}></div>
        <div className={styles.contentWrapper}>
          <span className={styles.indicator}>{ count }</span>
  
          <div className={styles.titleContainer}>
            <FontAwesomeIcon icon={['fal', icon]} className={styles.icon} />
            <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }}></h2>
          </div>
  
          <AccentBlock />
  
          <div className={styles.content} dangerouslySetInnerHTML={{ __html: blurb }}></div>
          <AnimatedButton to={to} message={'Learn More'} />
        </div>
  
        <div className={styles.imageWrapper}>
          <AnimatedImage image={image} className={styles.image} />
        </div>
      </section>
    )
  }
}

ServicesNode.defaultProps = {
  icon: 'handshake',
  image: {},
  position: 0,
  title: '',
  to: ''
}

export default ServicesNode